<template lang="pug">
  include ../mixins
  validation-observer.relative(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    ui-preloader(v-if="isLoad")
    +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="required|username"
          type="text"
          :input-label="_('Ваше ім`я')"
          v-model="formdata.firstName"
          name="firstName"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="required|username"
          type="text"
          :input-label="_('Ваше прізвище')"
          v-model="formdata.lastName"
          name="lastName"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="phone|required"
          type="tel"
          :input-label="_('Ваш телeфон')"
          v-model="formdata.phone"
          name="phone"
          mask="+38 (###) ###-##-##"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="email"
          type="text"
          :input-label="_('Email')"
          v-model="formdata.email"
          name="email"
        )
      +b.g-cell.g-cols.--12-xs
        authentication-privacy-policy(
          :text="_('Авторизуючись на сайті Ви погоджуєтеся з')"
        )
      +b.g-cell.g-cols--12-xs
        +b.BUTTON.control-button(
          @click.prevent="validateBeforeSubmit()"
          :disabled="isLoad"
        )
          +e.element
            +e.SPAN.text {{ _('Зареєструватися') }}
      validation-provider(
        slim
        v-slot="{ errors }"
        name="nonFieldErrors"
      )
        +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
          +b.P.error-label {{ errors[0] }}
</template>

<script>
import { registrationResource } from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import { setCookie } from '@utils/cookie'
import qs from 'qs'

export default {
  mixins: [FormMixin],

  props: {
    nextUrl: {
      type: String,
    },
  },

  data() {
    return {
      formdata: {
        firstName: '',
        lastName: '',
        emailPhone: '',
        password: '',
      },
    }
  },

  methods: {
    send(data) {
      const sendData = JSON.parse(JSON.stringify(data))

      return registrationResource.execute({}, sendData).then(res => {
        const { data: { item } } = res

        setCookie('code_send_at', new Date().getTime())

        const parsedParams = new URLSearchParams(window.location.search)
        const redirect = parsedParams.get('next')

        const params = {
          phone: item.phone,
          id_user: item.idUser,
          next: redirect,
        }

        const query = qs.stringify(params)

        window.location = `${this.nextUrl}?${query}`
      })
    },
  },
}
</script>
