<template lang="pug">
  include ../mixins
  +b.checkout(v-if="isCartResponse")
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit()"
    )
      +e.wrapper
        +e.main
          +e.heading
            slot(name="title")
          +e.card
            +b.card.--variant_7
              +b.ds-panel(
                :class="isAuth ? 'ds-panel--space_xl ds-panel--space_3xl-xl' : 'ds-panel--space_sm ds-panel--space_xl-xl'"
              )
                +e.element.--offset_bottom
                  +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Контактні дані") }}
              checkout-contact-form(
                v-if="isAuth"
                :order-data="formdata"
                :user="user"
                @update="contactDataChangeHandler"
              )
              div(v-else)
                +b.ui-tabs
                  +e.heading
                    +e.item.--variant_4(
                      @click.prevent="activateTab('new')"
                      :class="{ 'is-active': currentTab === 'new' }"
                    )
                      span {{ _("Новий покупець") }}
                    +e.item.--variant_4(
                      @click.prevent="activateTab('auth')"
                      :class="{ 'is-active': currentTab === 'auth' }"
                    )
                      span {{ _("Постійний покупець") }}
                  +e.content
                    +b.g-row.--appearance_spaced.--space_lg.--space_3xl-xl
                      +b.g-cell.g-cols.--12-xs.--auto-sm
                        checkout-contact-form(
                          v-if="currentTab === 'new'"
                          :order-data="formdata"
                          @update="contactDataChangeHandler"
                        )
                        authentication-controller(
                          v-if="currentTab === 'auth'"
                          @update="contactDataChangeHandler"
                          :need-reload="true"
                          :optimized="false"
                        )
                      +b.g-cell.g-cols.--12-xs.--narrow-sm
                        +b.i-divider.--variant_1
                          +e.content.--variant_1
                            +e.SPAN.text {{ _("або") }}
                      +b.g-cell.g-cols.--12-xs.--auto-sm
                        authentication-social-networks(
                          target-view="checkout"
                          :next-url="socialAuthNextUrl"
                        )
              +b.ds-panel.--space_lg.--space_3xl-xl(v-if="isAuth || (!isAuth && currentTab === 'new')")
                +e.element.--offset_top
                  +b.g-row
                    +b.g-cell
                      +b.LABEL.control-checkbox.--variant_flex
                        +e.INPUT.element(
                          v-model="formdata.isRecipient"
                          type="checkbox"
                          name="isRecipient"
                        )
                        +e.SPAN.label
                        +e.content
                          +b.P.ds-caption.--size_xs.--size_sm-lg.--color_grey {{ _("Вказати іншого отримувача") }}
          +e.card(v-if="formdata.isRecipient")
            +b.card.--variant_7
              +b.ds-panel.--space_xl.--space_3xl-xl
                +e.element.--offset_bottom
                  +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Введіть контактні дані отримувача") }}
              checkout-recipient-form(
                :order-data="formdata"
                @update="formdata = $event"
              )
          +e.card
            +b.card.--variant_7
              +b.ds-panel.--space_xl.--space_3xl-xl
                +e.element.--offset_bottom
                  +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Виберіть зручний спосіб доставки") }}
              checkout-delivery(
                :formdata="formdata"
                :cities-list="citiesList"
                :hide-warehouse-delivery="hideWarehouseDelivery"
                @change="deliveryChangeEvent"
                @delivery:calculate="calcDelivery"
              )
          +e.card
            +b.card.--variant_7
              +b.ds-panel.--space_xl.--space_3xl-xl
                +e.element.--offset_bottom
                  +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Виберіть зручний спосіб оплати") }}
              checkout-payment(
                :formdata="formdata"
                :is-pay-part="cartData.isPayParts"
                :is-pay-part-monobank="cartData.isMonobankPayPart"
                :is-pay-part-pumb="cartData.isPumbPayPart"
                :pay-parts-count="cartData.payPartsCount"
                :pay-parts-count-monobank="cartData.monobankPayPartsCount"
                :pay-parts-count-pumb="cartData.pumbPayPartsCount"
                :cart-price="computedFinalPrice"
                :is-apply-promocode="isApplyPromo"
                @preselect-payment="preselectPayment"
                @change="paymentChangeEvent"
                @mono:finance:phone:valid="setMonoFinancePhoneValid"
                @mono:finance:phone:load="setMonoFinancePhoneLoad"
              )
        +e.aside
          +e.heading.--variant_1
            +b.g-row.--justify_between.--align_center
              +b.g-cell
                +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Товари Вашого замовлення") }}
              +b.g-cell
                modal-handler(
                  url="Cart/CartView"
                  modal-class="vm-modal--size_2xl vm-modal--radius_none-till-xl"
                  #default="{ open }"
                )
                  +b.i-link.--size_sm(@click.prevent="open")
                    +e.SPAN.text.is-visible-xl {{ _("Редагувати") }}
                    +e.icon.--size_lg.--offset_left-xl
                      icon-edit-bookmark
          //- +e.card
          //-   +b.card.--variant_8
          //-     +b.ds-panel.--space_xl.--space_3xl-xl
          //-       +e.element.--offset_bottom
          //-         +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Промокод") }}
          //-     checkout-promocode(
          //-       :pay-part="'pay_part' === formdata.methodPay"
          //-       :method-pay="formdata.methodPay"
          //-       :cart-data="cartData"
          //-       @update-discount="handlePromocode($event)"
          //-       @promocode="formdata.promocode = $event"
          //-     )
          +e.card
            +b.card.--variant_9
              +b.card.--variant_11
                checkout-product(
                  v-for="item in cartData.products"
                  :key="item.id"
                  :item="item"
                )
          +e.card
            +b.card.--variant_10
              +b.ds-panel.--space_xl.--space_3xl-xl
                +e.element.--offset_bottom
                  +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Разом") }}
              +b.order-total
                +e.item
                  +b.g-row.--justify_between.--align_center
                    +b.g-cell
                      +b.P.ds-caption.--size_2xs {{ _("Товари на суму") }}
                    +b.g-cell
                      +b.P.ds-caption.--size_md.--weight_semi-bold {{ cartData.full|prettifyPrice }}
                        | 
                        +b.SPAN.ds-caption.--size_2xs.--size_md-xl.--weight_semi-bold {{ _("грн") }}
                +e.item(v-if="promocodeDiscount")
                  +b.g-row.--justify_between.--align_center
                    +b.g-cell
                      +b.P.ds-caption.--size_2xs {{ _("Знижка по промокоду") }}
                    +b.g-cell
                      +b.P.ds-caption.--size_2xs.--color_green {{ promocodeDiscount|prettifyPrice }}
                        | 
                        +b.SPAN.ds-caption.--size_2xs.--size_md-xl {{ _("грн") }}
                +e.item(v-if="cityRef")
                  +b.g-row.--justify_between.--align_center
                    +b.g-cell
                      +b.P.ds-caption.--size_2xs {{ _("Вартість доставки") }}
                    +b.g-cell
                      +b.P.ds-caption.--size_2xs.--size_md-xl.--color_green(
                        v-if="deliveryCost.freeDelivery"
                      ) {{ deliveryCost.cost }}
                      +b.P.ds-caption.--size_2xs.--size_md-xl(
                        v-else
                      ) {{ deliveryCost.cost }} {{ _("грн") }}
                +e.item.--variant_1
                  +b.g-row.--justify_between.--align_center
                    +b.g-cell
                      +b.P.ds-caption.--size_2xs {{ _("До оплати") }}
                    +b.g-cell
                      +b.P.ds-caption.--size_lg.--size_3xl-xl.--weight_semi-bold.--lh_1 {{ computedFinalPrice|prettifyPrice }}
                        | 
                        +b.SPAN.ds-caption.--size_2xs.--size_3md-xl.--weight_semi-bold {{ _("грн") }}
              +b.ds-panel.--space_xl
                +e.element.--offset_top
                  +b.g-row.--appearance_spaced.--space_lg.--space_3xl-xl
                    +b.g-cell.g-cols.--12-xs
                      +b.LABEL.control-checkbox.--variant_flex
                        +e.INPUT.element(
                          v-model="formdata.is_not_call"
                          type="checkbox"
                          name="is_not_call"
                        )
                        +e.SPAN.label
                        +e.content
                          +b.P.ds-caption.--size_xs.--size_sm-lg.--color_grey {{ _("Не телефонувати") }}
                    +b.g-cell.g-cols.--12-xs
                      d-control-textarea(
                        :input-label="_('Коментар до замовлення')"
                        input-class="d-control-input--height_2 d-control-input--variant_transparent"
                        v-model="formdata.comment"
                        spellcheck="true"
                        name="comment"
                      )
                    +b.g-cell.g-cols.--12-xs
                      +b.BUTTON.control-button.--variant_orange(
                        @click.prevent="validateBeforeSubmit()"
                        :disabled="isLoad"
                      )
                        +e.element
                          +e.SPAN.text {{ _('Купити') }}
                    +b.g-cell.g-cols.--12-xs
                      authentication-privacy-policy(
                        :text="_('Оформлюючи замовлення Ви погоджуєтеся з')"
                      )
                    validation-provider(
                      slim
                      v-slot=" {errors }"
                      name="nonFieldErrors"
                    )
                      +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
                        +b.P.error-label {{ errors[0] }}
</template>

<script>
import { valueFromSelect } from '@utils/utils'
import FormMixin from '@mixins/form.mixin.js'
import { createOrderResource } from '@services/order.service.js'
import { deliveryCostCalculateResource } from '@services/delivery.service.js'
import { cartListResource } from '@services/cart.service.js'
import { getAmplitudeInfo } from '@utils/amplitude'
import { mapActions, mapState } from 'vuex'
import { openMessageModal } from '@utils/submit'
import { clearPhone } from '@utils/transformers'
import LoginConfirm from '@app/Modals/Authentication/LoginConfirm'
import PhoneValidationInfo from '@app/Modals/Checkout/PhoneValidationInfo'

const CITIES_WITH_WAREHOUSE = [
  'db5c8900-391c-11dd-90d9-001a92567626',
]

export default {
  mixins: [FormMixin],

  components: {
    'checkout-contact-form': () => import('./ContactForm'),
    'checkout-recipient-form': () => import('./RecipientForm'),
    'checkout-delivery': () => import('./Delivery'),
    'checkout-payment': () => import('./Payment'),
    'checkout-promocode': () => import('./Promocode'),
    'checkout-product': () => import('./Product'),
  },

  data() {
    return {
      isAuth: window.isAuthenticated,
      policyPageUrl: window.policyPageUrl,
      rulesPageUrl: window.rulesPageUrl,
      socialAuthNextUrl: window.location.pathname,
      formdata: {
        financialPhone: '',
        nameBuyer: '',
        surnameBuyer: '',
        emailBuyer: '',
        phoneBuyer: '',
        register: false,
        city: '',
        refCity: '',
        deliveryDetail: '',
        warehouse: '',
        warehouseNp: '',
        streetDeliver: '',
        houseDeliver: '',
        flatDeliver: '',
        methodPay: '',
        nameLegalEntity: '',
        codeEdrpoy: '',
        comment: '',
        promocode: '',
        countParts: 0,
        phoneConfirmationInfo: {},
      },
      citiesList: [],
      cartData: {},
      promocodeDiscount: 0,
      isPromo: null,
      isPromocode: false,
      computedFinalPrice: 0,
      isCartResponse: false,
      getParams: {},
      isApplyPromo: false,
      amplitudeSendLogs: {
        contact: false,
      },
      deliveryCost: {
        freeDelivery: false,
        cost: null,
      },
      currentTab: 'new',
      monoFinancePhoneIsValid: false,
    }
  },

  watch: {
    cartCount: {
      deep: true,
      handler(nval) {
        if (!nval.quantity) {
          window.location.replace(window.indexPageUrl)
        } else {
          this.getCart()
        }
      },
    },

    user: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (this.isAuth) {
          this.prefillUserData(nval)
        }
      },
    },
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('cart', ['cartCount']),

    cityRef() {
      return this.formdata.city?.refCity || null
    },

    hideWarehouseDelivery() {
      if (CITIES_WITH_WAREHOUSE.includes(this.cityRef)) {
        return false
      }

      return true
    },
  },

  async mounted() {
    await this.getCart()

    this.addGtagStartCheckoutEvent()

    this.sendAmplitudeEvent('to_place_an_order')

    this.parseParameters()
  },

  methods: {
    ...mapActions('cart', [
      'getCartCount',
    ]),

    activateTab(val) {
      this.currentTab = val
    },

    showWarningModal() {
      const title = this._('Замовлення не створено')
      const text = this._('Перевірте правильність заповнення форми')

      openMessageModal({ title, text }, this)
    },

    codeConfirmationHandler(res) {
      this.$set(this.formdata, 'phoneConfirmationInfo', {
        needValidatePhone: false,
        idToken: res.id,
        phone: this.formdata.phoneBuyer,
      })

      this.validateBeforeSubmit()
    },

    openConfirmationModal() {
      this.$modal.show(LoginConfirm, {
        info: {
          token: clearPhone(this.formdata.phoneBuyer),
          callback: this.codeConfirmationHandler,
        },
      })
    },

    openPreConfirmationModal() {
      this.$modal.show(PhoneValidationInfo, {
        callback: this.openConfirmationModal,
      })
    },

    async send(data) {
      const copyData = JSON.parse(JSON.stringify(data))

      if (copyData.phoneConfirmationInfo.needValidatePhone) {
        this.openPreConfirmationModal()

        return false
      }

      const isMono = 'mono_pay_part' === copyData.paymentBank

      if (!this.monoFinancePhoneIsValid && isMono) {
        this.showWarningModal()

        return false
      }

      if (!copyData.financialPhone) {
        delete copyData.financialPhone
      }

      copyData.getParams = this.getParams

      const formdata = await this.formatBeforeRequest(copyData)

      return createOrderResource.execute({}, formdata).then(res => {
        const { data: { item: { location, transactionId } } } = res

        this.orderCreatedEvent(transactionId)

        window.location.replace(location)
      })
    },

    getItemsForDataLayer() {
      const { products } = this.cartData

      return products.map(el => {
        const {
          code, title, prices,
          color, category, count,
        } = el
        const { price2 } = prices

        return {
          item_id: code,
          item_name: title,
          item_category: category,
          price: price2,
          quantity: count,
          item_variant: color || '',
        }
      })
    },

    orderCreatedEvent(transactionId) {
      const items = this.getItemsForDataLayer()
      const { finalPrice } = this.cartData

      /* eslint-disable-next-line */
      gtag('event', 'purchase', {
        currency: 'UAH',
        value: finalPrice,
        shipping: 0,
        transaction_id: transactionId,
        items,
      })
    },

    formatBeforeRequest(data) {
      data.refCity = data.city.refCity
      data.refCityAddress = data.city.refCityAddress
      data.city = valueFromSelect(data.city, 'presentTitle')
      data.warehouse = valueFromSelect(data.warehouse, 'id')
      data.warehouseNp = valueFromSelect(data.warehouseNp, 'presentTitle')
      data.streetDeliver = valueFromSelect(data.streetDeliver, 'presentTitle')

      if (data.deliveryDetail === 'fromWarehouse') {
        data.city = data.warehouse.title

        delete data.refCity
        delete data.refCityAddress
      }

      if ('pay_part' === data.methodPay && data.paymentBank) {
        data.methodPay = data.paymentBank

        delete data.paymentBank
      }

      if (data.phoneConfirmationInfo.idToken) {
        data.idToken = data.phoneConfirmationInfo.idToken
      }

      delete data.phoneConfirmationInfo

      return data
    },

    async getCart() {
      this.isLoad = true

      return cartListResource.execute().then(({ data: { item } }) => {
        if (item?.products?.length) {
          this.cartData = item

          this.isCartResponse = true

          this.isLoad = false

          this.computedFinalPrice = item.finalPrice

          this.isPromo = item.isPromo
        } else {
          window.location.replace(window.indexPageUrl)
        }

        this.isApplyPromo = false
      })
    },

    handlePromocode(promocode) {
      this.promocodeDiscount = promocode
      this.computedFinalPrice = this.cartData.finalPrice - promocode
      this.isPromocode = true
      /* isApplyPromo is neccessary to understand can we
        update part payment or keep old max pay count
      */
      this.isApplyPromo = 0 !== promocode
    },

    prefillUserData(data) {
      this.formdata.phoneConfirmationInfo = { needValidatePhone: false }
      this.formdata.nameBuyer = data.firstName
      this.formdata.surnameBuyer = data.lastName
      this.formdata.emailBuyer = data.email
      this.formdata.phoneBuyer = data.phone ? `+${data.phone}` : ''

      if (data.city && 'None' !== data.city && data.refCity && data.refCityAddress) {
        this.formdata.city = {
          presentTitle: data.city,
          refCity: data.refCity,
          refCityAddress: data.refCityAddress,
        }

        this.calcDelivery()
      }
    },

    parseParameters() {
      const url = new URL(window.location.href)
      const searchParams = url.search.replace('?', '')
      const paramsElements = searchParams.split('&')

      paramsElements.forEach(el => {
        const data = el.split('=')
        const [key, value] = data
        this.getParams[key] = value
      })
    },

    preselectPayment() {
      this.formdata.methodPay = 'pay_part'
    },

    calcDelivery() {
      if (!this.cityRef) return

      const formdata = {
        refCity: this.cityRef || null,
        deliveryDetail: this.formdata.deliveryDetail || null,
      }

      deliveryCostCalculateResource.execute({}, formdata).then(res => {
        const { data } = res

        this.deliveryCost = data.item
      })
    },

    contactDataChangeHandler(data) {
      this.formdata = data

      const fields = ['nameBuyer', 'surnameBuyer', 'phoneBuyer']

      const isFilled = fields.every(k => this.formdata[k])
      const { errors } = this.$refs.observer
      const hasNotErrors = fields.every(k => !errors[k].length)

      if (isFilled && hasNotErrors && !this.amplitudeSendLogs.contact) {
        this.amplitudeSendLogs.contact = true

        this.sendAmplitudeEvent('to_place_an_order_newbuyer_contact_filled')
      }
    },

    setMonoFinancePhoneValid(val) {
      this.monoFinancePhoneIsValid = val
    },

    setMonoFinancePhoneLoad(val) {
      this.isLoad = val
    },

    getItemsForAnalytics() {
      const { products } = this.cartData

      return products.map(el => {
        const {
          code, title, prices,
          color, category, count,
        } = el
        const { price2 } = prices

        return {
          id: code,
          name: title,
          category,
          price: price2,
          quantity: count,
          variant: color || '',
        }
      })
    },

    addGtagStartCheckoutEvent() {
      const { finalPrice } = this.cartData
      const items = this.getItemsForAnalytics()

      /* eslint-disable-next-line */
      gtag('event', 'begin_checkout', {
        items,
        currency: 'UAH',
        value: finalPrice,
      })
    },

    deliveryChangeEvent(option) {
      this.gtagDeliveryChange(option)

      this.sendAmplitudeEvent('to_place_an_order_newbuyer_delivery_selected')
    },

    paymentChangeEvent(option) {
      this.gtagPaymentChange(option)

      this.sendAmplitudeEvent('to_place_an_order_newbuyer_paymentmethod_selected')
    },

    gtagDeliveryChange(option) {
      /* eslint-disable-next-line */
      gtag('event', 'set_checkout_option', {
        checkout_step: 1,
        checkout_option: 'shipping method',
        value: option,
      })
    },

    gtagPaymentChange(option) {
      /* eslint-disable-next-line */
      gtag('event', 'set_checkout_option', {
        checkout_step: 1,
        checkout_option: 'payment method',
        value: option,
      })
    },

    sendAmplitudeEvent(event) {
      /* eslint-disable no-undef */
      if (!amplitude) return

      const info = getAmplitudeInfo()
      const { finalPrice } = this.cartData

      info.amount = finalPrice

      /* eslint-disable no-undef */
      amplitude.logEvent(event, info)
    },
  },
}
</script>
