<template lang="pug">
include ../mixins
swiper(:options="swiperOptions")
  slot
</template>

<script>
export default {
  data() {
    return {
      initialized: false,
      swiperOptions: {
        autoHeight: true,
        spaceBetween: 10,
        navigation: {
          prevEl: '.js-payment-prev',
          nextEl: '.js-payment-next',
        },
        slidesPerView: 3,
        breakpoints: {
          359: {
            slidesPerView: 4,
          },
          564: {
            spaceBetween: 8,
          },
        },
      },
    }
  },
}
</script>
