<template lang="pug">
  include ../mixins
  div
    authentication-login(
      v-if="activeMethod === 'phone'"
      :need-reload="needReload"
      :optimized="optimized"
      @method:change="methodChangeHandler"
    )
    authentication-login-email(
      v-if="activeMethod === 'email'"
      :need-reload="needReload"
      :optimized="optimized"
      @method:change="methodChangeHandler"
    )
</template>

<script>
export default {
  props: {
    needReload: {
      type: Boolean,
    },
    optimized: {
      type: Boolean,
    },
  },

  data() {
    return {
      activeMethod: 'phone',
    }
  },

  methods: {
    methodChangeHandler(val) {
      this.activeMethod = val
    },
  },
}
</script>
