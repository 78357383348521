<template lang="pug">
  include ../mixins
  +b.g-row.--space_md.--appearance_spaced
    +b.g-cell.g-cols.--12-xs(v-if="view[targetView].google")
      +b.A.control-button.--variant_grey(
        :href="googleAuthUrl"
      )
        +e.element
          +e.icon.--variant_2.--color_accent-fill
            icon-google-outline
          +e.SPAN.text {{ _("Увійти через Google") }}
    +b.g-cell.g-cols.--12-xs(v-if="view[targetView].facebook")
      +b.A.control-button.--variant_grey(
        :href="facebookAuthUrl"
      )
        +e.element
          +e.icon.--variant_2.--color_accent-fill
            icon-facebook-outline
          +e.SPAN.text {{ _("Увійти через Facebook") }}
    //- +b.g-cell.g-cols.--12-xs(v-if="view[targetView].apple")
    //-   +b.A.control-button.--variant_grey(
    //-     :href="appleAuthUrl"
    //-   )
    //-     +e.element
    //-       +e.icon.--variant_2.--color_accent-fill
    //-         icon-apple-outline
    //-       +e.SPAN.text {{ _("Увійти через Apple") }}
</template>

<script>
export default {
  props: {
    targetView: {
      type: String,
      default: 'base',
    },
    nextUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      googleAuthUrl: this.nextUrl ? `${window.googleAuthUrl}?next=${this.nextUrl}` : window.googleAuthUrl,
      facebookAuthUrl: this.nextUrl ? `${window.facebookAuthUrl}?next=${this.nextUrl}` : window.facebookAuthUrl,
      // appleAuthUrl: this.nextUrl ? `${window.appleAuthUrl}?next=${this.nextUrl}` : window.appleAuthUrl,
      view: {
        base: {
          google: true,
          facebook: true,
          // apple: true,
        },
        checkout: {
          google: true,
          facebook: false,
          // apple: true,
        },
      },
    }
  },
}
</script>
