<template lang="pug">
  include ../../mixins
  +b.modal.--variant_heading
    +b.close(@click="$emit('close')")
      icon-close
    +e.heading
      +b.P.ds-caption.--size_3md.--size_lg-xl.--weight_semi-bold {{ _("Перед тим як створити замовлення підтвердіть номер телефону") }}
    +e.content
      +b.P.ds-caption.--size_xs.--size_sm-lg {{ _("Вам буде надіслано код підтвердження на вказаний в замовленні номер телефону") }}
      +b.ds-panel.--space_lg
        +e.element.--offset_top
          +b.BUTTON.control-button(
            @click.prevent="submit"
          )
            +e.element
              +e.SPAN.text {{ _("Підтвердити номер телефону") }}
</template>

<script>
export default {
  props: {
    callback: {},
  },

  methods: {
    submit() {
      this.$emit('close')

      setTimeout(() => {
        this.callback()
      }, 200)
    },
  },
}
</script>
