import {
  postResource,
  patchResource,
  getResource,
  deleteResource,
  createResource,
} from '@resource/resource'
import { CABINET } from './paths'

export const userDataResource = createResource(
  CABINET.USER, getResource
)

export const updateUserDataResource = createResource(
  CABINET.USER_UPDATE, postResource
)

export const userSocialAuthDeleteResource = createResource(
  CABINET.SOCIAL_AUTH_DELETE, deleteResource
)

export const favoriteListResource = createResource(
  CABINET.FAVORITE_LIST, getResource
)

export const trackedListResource = createResource(
  CABINET.TRACKED_LIST, getResource
)

export const ticketsListResource = createResource(
  CABINET.TICKETS_LIST, getResource
)

export const ticketCreateResource = createResource(
  CABINET.TICKET_CREATE, postResource
)

export const ticketMessageCreateResource = createResource(
  CABINET.TICKET_MESSAGE_CREATE, postResource
)

export const ticketDetailResource = createResource(
  CABINET.TICKET_DETAIL, postResource
)

export const ticketCloseResource = createResource(
  CABINET.TICKET_CLOSE, postResource
)

export const ordersListResource = createResource(
  CABINET.ORDERS_LIST, getResource
)

export const ordersCancelResource = createResource(
  CABINET.ORDERS_CANCEL, postResource
)

export const ordersPaymentMethodPayloadResource = createResource(
  CABINET.ORDERS_PAYMENT_METHODS_PAYLOAD
)

export const ordersChangePaymentMethodResource = createResource(
  CABINET.ORDERS_CHANGE_PAYMENT_METHOD, patchResource
)
